* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.soon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;

  padding: 5vh 0;
}

.soon h2 {
  color: rgb(29, 31, 32);
  font-weight: bold;
  font-size: 2vmax;
}
